import React, { useEffect } from 'react';
import HeroSection from '../../components/HeroSection';
import Section from '../../components/Section';
import { navigate } from 'gatsby';
import webservices from '../../constants/webservices';
import Redirecting from '../../components/Redirecting';

const PayNow = ({ location }) => {
	useEffect(() => {
		if (!location.state || !location.state.authenticated) {
			navigate('/web-services/login', {
				state: {
					title: webservices.payNow.title,
					body: webservices.payNow.description,
					redirectOnLogin: webservices.payNow.url,
				},
				replace: true,
			});
		}
	}, [location.state]);

	if (!location.state) return null;

	if (location.state.isHMCTS) {
		window.open(
			`https://www.jacobshmcts.com/CollectPortal/index.html?bid=${encodeURIComponent(
				location.state.branchID
			)}`
		);
	} else {
		window.open(
			`https://clientweb.jacobsenforcement.com/CollectPortal/index.html?bid=${encodeURIComponent(
				location.state.branchID
			)}`
		);
	}

	return (
		<>
			<HeroSection />
			<Section
				backgroundColor='grey'
				paddingTop='74px'
				paddingBottom='80px'
			>
				<Redirecting />
			</Section>
		</>
	);
};

export default PayNow;

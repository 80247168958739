import React from 'react';
import Card from '../Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDirections } from '@fortawesome/free-solid-svg-icons';
import { Heading1 } from '../Typography';
import Button from '../Button';

const Redirecting = () => {
  return (
    <Card
      p={40}
      maxWidth='386px'
      mx='auto'
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <div>
        <FontAwesomeIcon
          icon={faDirections}
          color='#26326a'
          size='5x'
          margin='0 auto'
        />
      </div>
      <Heading1 textAlign='center' mt={32}>
        Redirected to Payment Portal in new tab.
      </Heading1>{' '}
      <Button href='/' as='a'>
        Return to Homepage
      </Button>{' '}
    </Card>
  );
};

export default Redirecting;
